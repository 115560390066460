import React, { useCallback } from "react"

import { faChild, faClock, faPeopleGroup, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { skipToken } from "@reduxjs/toolkit/query"

import Button from "components/ui/Button"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/Table"
import { useGetUserGamesQuery, useDeleteUserGameMutation } from "services/userGamesApi"
import { Game } from "types"

interface ShelfProps {
  shelfId?: string
}

const Shelf: React.FC<ShelfProps> = ({ shelfId }) => {
  const { data: games } = useGetUserGamesQuery(shelfId ? shelfId : skipToken)
  const [deleteUserGameApi] = useDeleteUserGameMutation()

  const handleDelete = useCallback(
    async (id: string) => {
      if (window.confirm("Are you sure you want to delete this game?")) {
        await deleteUserGameApi({ id })
      }
    },
    [deleteUserGameApi],
  )

  return (
    <div className='flex-1 flex-col'>
      {shelfId && games ? (
        <Table className='w-full'>
          <TableHeader>
            <TableRow>
              <TableHead></TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {games?.map(game => {
              const gameInfo = game.Games as unknown as Game
              return (
                <TableRow key={gameInfo.bgg_game_id}>
                  <TableCell>
                    <div className='gameBox flex items-center align-center gap-x-4'>
                      <div className='gameImage'>
                        {gameInfo.thumbnail && (
                          <img
                            src={gameInfo.thumbnail}
                            alt={gameInfo.name}
                            className='rounded-md min-w-20 min-h-20'
                            height={100}
                            width={100}
                          />
                        )}
                      </div>
                      <div className='gameInfo'>
                        <div className='gameName font-bold'>{gameInfo.name}</div>
                        <div className='gameYear'>Published: {gameInfo.year_published}</div>
                        <div className='gamePlayers'>
                          <FontAwesomeIcon icon={faPeopleGroup} className='fa-fw' /> : {gameInfo.min_players}-
                          {gameInfo.max_players}
                        </div>
                        <div className='gameAge'>
                          <FontAwesomeIcon icon={faChild} className='fa-fw' /> : {gameInfo.age}+
                        </div>
                        <div className='gameTime'>
                          <FontAwesomeIcon icon={faClock} className='fa-fw' /> : {gameInfo.playing_time} minutes
                        </div>
                      </div>
                    </div>
                    {gameInfo.thumbnail && <div className='flex items-center justify-center'></div>}
                  </TableCell>
                  <TableCell>
                    <div className='flex items-center justify-center gap-x-4'>
                      <a
                        href={`https://boardgamegeek.com/boardgame/${gameInfo.bgg_game_id}`}
                        className='font-semibold text-primary'
                        target='_blank'
                        rel='noreferrer'
                      >
                        BGG
                      </a>
                      <Button variant='destructive' onClick={() => handleDelete(game.id)} size='sm'>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      ) : (
        <div>Your shelf is empty!</div>
      )}
    </div>
  )
}

export default Shelf
