import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { RootState } from "@reduxjs/toolkit/query"
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from "redux-persist" // Add this import
import storage from "redux-persist/lib/storage"

import { bggApi, gamesApi, shelvesApi, usersApi, userGamesApi } from "services"

const rootReducer = combineReducers({
  [bggApi.reducerPath]: bggApi.reducer,
  [gamesApi.reducerPath]: gamesApi.reducer,
  [shelvesApi.reducerPath]: shelvesApi.reducer,
  [userGamesApi.reducerPath]: userGamesApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
})

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = (preloadedState?: RootState<any, any, any>) => {
  return configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        bggApi.middleware,
        gamesApi.middleware,
        shelvesApi.middleware,
        userGamesApi.middleware,
        usersApi.middleware,
      ),
  })
}

export type RootStore = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof store>
export type AppDispatch = AppStore["dispatch"]

export default store
