import { Routes, Route, Navigate } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import Layout from "components/Layout"
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute"
import { useSession } from "hooks/Supabase/useSession"
import GameKeep from "pages/GameKeep"
import Login from "pages/Login"
import NotFound from "pages/NotFound"
import Profile from "pages/Profile"

import "./App.css"
import "react-toastify/dist/ReactToastify.css"

function App() {
  const { isAuthenticated } = useSession()

  return (
    <div className='App' data-testid='app'>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={isAuthenticated ? <Navigate to='/GameKeep' /> : <Navigate to='/Login' />} />
          <Route path='Login' element={<Login />} />
          <Route
            path='GameKeep'
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <GameKeep />
              </ProtectedRoute>
            }
          />
          <Route
            path='Profile'
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route path='*' element={isAuthenticated ? <NotFound /> : <Navigate to='/Login' />} />
        </Route>
      </Routes>
      <ToastContainer position='top-center' />
    </div>
  )
}

export default App
